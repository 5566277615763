import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import PageFrameContainer from '@shared/ui/layout/PageFrameContainer';
import { useAppDispatch, useAppSelector } from '@app/store/Hooks';
import { editProfileInfo, ProfilePreview, ProfileViewMode, } from '@entities/profile';
import { GetVerificationBanner } from '@features/profile/profile-info';
import { ContainerColumn, ContainerRow } from '@components/styled';
import ProfileTabsWidget from '@widgets/ProfileTabsWidget';
import Button from '@shared/ui/buttons/Button';
import { ReactComponent as EditIcon } from '@icons/wolfkit-light/pencil-light.svg';
import { ProfileEdit } from '@features/profile/profile-edit';
import { IsDefined } from '@utils/js-ts';
import { useGetMeQuery } from '@shared/api/current-user';
const ProfileHeaderContainer = styled(ContainerColumn)(() => ({
    height: 'auto',
    minHeight: '280px',
    justifyContent: 'space-between',
    gap: '48px',
}));
const ContentContainer = styled(ContainerRow)(() => ({
    height: 'auto',
    justifyContent: 'space-between',
}));
const MyProfile = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { isLoading: isLoadingUserData } = useGetMeQuery();
    const { currentUserProfile, viewMode } = useAppSelector(state => state.profile);
    const displayVerificationBanner = viewMode === ProfileViewMode.View &&
        IsDefined(currentUserProfile) &&
        currentUserProfile.type === 'TRADER' &&
        !currentUserProfile.isVerifiedTrader;
    const onSwitchToEditClick = () => {
        if (currentUserProfile) {
            dispatch(editProfileInfo());
        }
    };
    return (_jsx(PageFrameContainer, { header: (!!currentUserProfile || isLoadingUserData) && (_jsxs(ProfileHeaderContainer, { children: [displayVerificationBanner && _jsx(GetVerificationBanner, {}), _jsxs(ContentContainer, { children: [viewMode === ProfileViewMode.View && (_jsx(ProfilePreview, { profile: currentUserProfile, isLoading: isLoadingUserData, actionBtns: (_jsx(Button, { variant: 'filled', size: 'small', color: 'primary', startIcon: EditIcon, onClick: onSwitchToEditClick, children: t('profile.edit') })) })), viewMode === ProfileViewMode.Edit && (_jsx(ProfileEdit, {}))] }), _jsx(ProfileTabsWidget, { profileNickname: (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.username) || '' })] })), gap: theme.spacing_sizes.l, headerStyles: {
            padding: `${theme.spacing_sizes.s * 5}px 0 0`,
        }, bottom: (_jsx(Outlet, { context: {
                profileId: currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id,
                nickname: currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.username,
                profileType: (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) || 'undefined',
                isMyProfile: true,
                isVerifiedTrader: currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.isVerifiedTrader,
            } })) }));
};
export default MyProfile;
