import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ContainerColumn } from '@src/components/styled';
import SegmentButtons from '@shared/ui/buttons/SegmentButtons';
import TransactionHistoryTable from '../TransactionHistoryTable';
const FilterContainer = styled.div(() => ({
    maxWidth: 410,
}));
const PlatformBalanceHeader = styled(ContainerColumn)(props => ({
    width: 'auto',
    padding: `${props.theme.spacing_sizes.xs * 5}px 0px`,
    gap: props.theme.spacing_sizes.xs * 5,
}));
const ClientPlatformBalanceHeader = ({ isLoading, data = [], }) => {
    const { t } = useTranslation();
    const [activeTable, setActiveTable] = useState('all_transactions');
    return (_jsxs(PlatformBalanceHeader, { children: [_jsx(FilterContainer, { children: _jsx(SegmentButtons, { currentSegmentKey: activeTable, segments: [
                        { key: 'all_transactions', displayValue: t('balance.client_balance.transactions_filter.all') },
                        { key: 'subscriptions', displayValue: t('balance.client_balance.transactions_filter.subscriptions') },
                        { key: 'refunds', displayValue: t('balance.client_balance.transactions_filter.refunds') }
                    ], onSelect: (value) => setActiveTable(value) }) }), _jsx(TransactionHistoryTable, { data: data, isLoading: isLoading })] }));
};
export default ClientPlatformBalanceHeader;
