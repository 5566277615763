import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CollapsePanel from '@shared/ui/layout/CollapsePanel';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import { ReactComponent as ChevronIcon } from '@icons/wolfkit-solid/chevron-small-down-solid.svg';
import { TitleSecondary } from '@src/components/styled/Typography';
import Button from '@shared/ui/buttons/Button';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import TransactionHistoryTable from '../TransactionHistoryTable';
const PlatformBalanceBottomContainer = styled(ContainerColumn)(props => ({
    borderRadius: 12,
    background: '#fff',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
    padding: `${props.theme.spacing_sizes.l}px ${props.theme.spacing_sizes.xs * 5}px`,
    boxSizing: 'border-box',
}));
const TableHeaderContainer = styled(ContainerRow)(() => ({
    justifyContent: 'space-between',
}));
const TraderPlatformBalanceBottom = ({ isLoading = false, data = [], }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const handleToggle = () => setIsOpen((prev) => !prev);
    return _jsxs(PlatformBalanceBottomContainer, { children: [_jsxs(TableHeaderContainer, { children: [_jsx(WithSkeleton, { isLoading: isLoading, width: 154, height: 36, children: _jsx(TitleSecondary, { children: t('balance.transaction_history_table.title') }) }), _jsx(WithSkeleton, { isLoading: isLoading, width: 32, height: 32, children: _jsx(Button, { size: 'small', variant: 'outline', color: 'secondary', startIcon: ChevronIcon, startIconProps: {
                                size: 16,
                                style: {
                                    transform: `rotate(${isOpen ? 180 : 0}deg)`,
                                },
                            }, onClick: handleToggle }) })] }), _jsx(CollapsePanel, { isOpen: isOpen, children: _jsx(TransactionHistoryTable, { data: data, isLoading: isLoading }) })] });
};
export default TraderPlatformBalanceBottom;
